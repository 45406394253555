import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilChartPie,
  cilPuzzle,
  cilSpeedometer,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'
const CustomerSidebarData = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/customer/dashboard',
    icon:  <div className='radius-svg'> <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    fill="none"
    viewBox="0 0 24 26"
    className='home-svg'
  >
    <path
      fill="#fff"
      d="M13.181 1.204a1.921 1.921 0 00-2.64 0L1.234 10a2.882 2.882 0 00-.901 2.097v10.702a2.882 2.882 0 002.882 2.88h2.882a2.882 2.882 0 002.882-2.882v-4.804a.96.96 0 01.961-.96h3.843a.96.96 0 01.96.96v4.804a2.882 2.882 0 002.883 2.882h2.882a2.882 2.882 0 002.882-2.882V12.095A2.883 2.883 0 0022.487 10L13.18 1.2v.004z"
    ></path>
  </svg></div>
  },
  {
    component: CNavItem,
    name: 'History',
    to: '/customer/history',
    icon: <div className='radius-svg'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="24"
        height="26">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>

    </div>
  },
  {
    component: CNavItem,
    name: 'Account',
    to: '/customer/account',
    icon: <div className='radius-svg'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="24"
        height="26">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>

    </div>
  },
//   {
//     component: CNavItem,
//     name: 'All Account Trips',
//     to: '/all-account-trips',
//     icon: <div className='radius-svg'>
//       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="24"
//         height="26">
//         <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
//       </svg>

//     </div>
//   },
//   {
//     component: CNavGroup,
//     name: 'Setting',
//     to: '',
//     icon: <div className='radius-svg'>
//       <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
//         <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
//       </svg></div>,
//     items: [
//       {
//         component: CNavItem,
//         name: 'Edit Profile',
//         to: '/edit-driver-data',
//       },
//       {
//         component: CNavItem,
//         name: 'Change Password',
//         to: '/change-password',
//       },

//     ],
//   },

  
]

export default CustomerSidebarData;
