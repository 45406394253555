import React, { useEffect, useState, useContext, useRef } from "react";
import SidebarDriver from "../Sidebar";
import AppHeader from "../../TopBar/AppHeader";
import AppLoader from "../../AppLoader";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CAvatar,
    CModal,
    CModalBody
} from "@coreui/react";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { _GetAllAccountTrips, accessEditTrip } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import EmptyData from "../../EmptyData";
import moment from "moment";
import { Link } from "react-router-dom";
import accepticonimg from "../../../assets/images/accept.png"
import deletepopup from "../../../assets/images/deletepopup.png"
import deleteiconimg from "../../../assets/images/deleteicon.png"
import editicon from "../../../assets/images/editicon.png"
import backtovehicle from "../../../assets/images/left-arrow.png";
import { socketContext } from "../../../App";




const AllAccountTrips = () => {

    const { socket } = useContext(socketContext);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [tripsData, setTripsData] = useState([]);
    const [originalTripsData, setOriginalTripsData] = useState([]); // New state for original pending list

    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(3);
    const [maxPage, setMaxPage] = React.useState(3);
    const [minPage, setMinPage] = React.useState(0);
    const [tripType, setTripsType] = useState("Pending");
    const [selectedId, setSelectedId] = useState(null);
    const [delvisible, setDelvisible] = useState(false);
    const [companyId, setCompanyId] = useState(null);



    useEffect(() => {
        if (!socket) {
            console.error('Socket is not defined');
            return;
        }


        socket.on('refreshTrip', ({ trip }) => {
            console.log('Socket event "tripAcceptedBYDriver" received');
            //   alert('Trip accepted by driver');

            getAccessTrips();
        });


    }, [socket])


    const id = useParams().id;


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);

        console.log("origninal", originalTripsData)
        // Filter the access list based on the search value
        const filteredAccessList = originalTripsData.filter(
            (item) =>
                item.company_name.toLowerCase().includes(value.toLowerCase()) ||
                item.vehicle_type.toLowerCase().includes(value.toLowerCase()) ||
                item.trip_id.toLowerCase().includes(value.toLowerCase()) ||
                item.trip_from?.address.toLowerCase().includes(value.toLowerCase()) ||
                item.trip_to?.address.toLowerCase().includes(value.toLowerCase()) ||
                item.comment.toLowerCase().includes(value.toLowerCase())
        );

        // Update accessList with filtered data or reset it to original
        setTripsData(value === "" ? originalTripsData : filteredAccessList);

        // Reset current page to 1 when search changes
        setCurrentPage(1);
    };

    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = tripsData?.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(tripsData?.length / recordPage);
    const number = [...Array(nPage + 1)?.keys()]?.slice(1);

    const pageNumber = number.map((num, i) => {
        if (num < maxPage + 1 && num > minPage) {
            return (
                <>
                    <li
                        key={i}
                        className={currentPage == num ? `active_btn ` : `unactive_btn`}
                    >
                        <button onClick={() => changePage(num)}>{num}</button>
                    </li>
                </>
            );
        } else {
            return null;
        }
    });

    const handlePrePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
            if ((currentPage - 1) % pageLimit == 0) {
                setMaxPage(maxPage - pageLimit);
                setMinPage(minPage - pageLimit);
            }
        }
    };

    const handleNextPage = () => {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
            if (currentPage + 1 > maxPage) {
                setMaxPage(maxPage + pageLimit);
                setMinPage(minPage + pageLimit);
            }
        }
    };

    const changePage = (id) => {
        setCurrentPage(id);
    };
    let pageIncreament = null;
    if (data?.length > maxPage) {
        pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
    }

    useEffect(() => {
        getAccessTrips();
    }, [tripType]);

    const getAccessTrips = () => {
        setLoader(true);

        let data = { comment: "", company_id: id }

        _GetAllAccountTrips(tripType, data).then((res) => {

            console.log("res trips _", res)
            setTripsData(res?.result)
            setOriginalTripsData(res?.result)

        }).catch(() => {
            setLoader(false)
        }).finally(() => {
            setLoader(false)
        })
    }

    const TripsFilter = (event) => {
        setTripsType(event.target.value)
    }

    const handleDeletItem = () => {
        console.log("test")

        let datas = {
            trip_status: 'Canceled'
        }
        setLoader(true)
        accessEditTrip(selectedId, companyId, datas).then((res) => {
            console.log(res, "noo_")
            setDelvisible(false);
            getAccessTrips()
        }).catch(() => {
            setLoader(false)
        })
    }

    const retrieveTrip = (ids, data) => {
        console.log("test ", id, data)

        let dat = {
            trip_status: 'Pending'
        }
        setLoader(true)

        accessEditTrip(ids, id, dat).then((res) => {
            console.log(res, "noo_")


            let driverId = res?.result?.driver_name;
            let trip = res;

            console.log("driver Reterieve", driverId, "code ", trip)

            socket.emit("companyCancelledTrip", { driverId, trip });

            setDelvisible(false);
            getAccessTrips()
        }).catch(() => {
            setLoader(false)
        })
    }

    return (
        <>
            <div>
                <div className="container-fluidd">
                    <div className="col-md-12">
                        <div>
                            <SidebarDriver />
                            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                                <AppHeader />
                                <div
                                    className="body flex-grow-1 px-3"
                                    style={{ paddingBottom: "20px" }}
                                >
                                    <div className="mb-3 ">
                                        <h1 className="heading-for-every-page"><Link to={"/account-access"}>  <img src={backtovehicle} alt="edit" />Access Trips</Link></h1>
                                        <div className="d-flex">
                                            <select
                                                value={tripType}
                                                onChange={TripsFilter}
                                                style={{
                                                    backgroundColor: '#f9b115',
                                                    color: 'black', // You can change the color to match your design
                                                    border: '1px solid #ccc', // You may adjust the border color and width
                                                    borderRadius: '5px', // You can adjust the border radius as needed
                                                    padding: '2px', // You can adjust the padding as needed
                                                    height: "39px"
                                                }}
                                            >
                                                <option value="Pending">Pending Trips</option>
                                                <option value="Approved">Approved Trips</option>
                                                <option value="Booked">Booked Trips</option>
                                                <option value="Reached">Active Pickups</option>
                                                <option value="Active">Active Trips</option>
                                                <option value="Completed">Completed Trips</option>
                                                <option value="Canceled">Cancelled Trips</option>

                                            </select>
                                            {/* <div><Link to={`/access-request-trip/${id}`} className="btn btn-warning ms-2">Request Trip</Link></div> */}
                                            <div className="serach-left ms-auto" id="pending-trip-search">
                                                <MDBInputGroup>
                                                    <MDBInput placeholder="Search" value={search} onChange={handleSearchChange} />

                                                </MDBInputGroup></div>
                                        </div>

                                    </div>

                                    {/* <div className="active-trip-outer"> */}
                                    {loader ? <AppLoader /> : <div className="active-trip-outer">

                                        {data?.length > 0 ?
                                            <CTable align="middle" className="mb-0" hover responsive>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell className="text-center">
                                                            S. No.
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Company Name
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Trip ID
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Vehicle Type
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Trip From
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Trip To
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Comment
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Commission
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Time
                                                        </CTableHeaderCell>
                                                        {data?.some(item => item.trip_status === "Pending" || item.trip_status === "Booked" || item.trip_status == "Approved") && (
                                                            <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                                                        )}
                                                        <CTableHeaderCell className="text-center">View Ride</CTableHeaderCell>
                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {data?.length > 0
                                                        ? data?.map((item, index) => (
                                                            <CTableRow
                                                                className="text-center"
                                                                v-for="item in tableItems"
                                                                key={item._id}
                                                            >
                                                                <CTableDataCell>
                                                                    <div>{firstIndex + index + 1}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.company_name}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.trip_id}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.vehicle_type}</div>
                                                                </CTableDataCell>

                                                                <CTableDataCell>
                                                                    <div>{item?.trip_from?.address.slice(0, 20) + `${item?.trip_from?.address?.length < 21 ? "" : "..."}`}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.trip_to?.address.slice(0, 20) + `${item?.trip_to?.address?.length < 21 ? "" : "..."}`}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>
                                                                        {item?.comment}
                                                                    </div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>
                                                                        {item?.commission?.commission_value}{item?.commission?.commission_type != "Fixed" ? "%" : ""}
                                                                    </div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>
                                                                        {moment(item.pickup_date_time).format(
                                                                            "MMMM Do YYYY, h:mm a"
                                                                        )}
                                                                    </div>
                                                                </CTableDataCell>
                                                                {
                                                                    item.trip_status == "Pending" ?
                                                                        <CTableDataCell className="pending-trips-icons" >
                                                                            <div style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-evenly",
                                                                                alignItems: "center",
                                                                            }} className="pending-icons-outer">
                                                                                <div>
                                                                                    <Link to={`/access-allocate-driver/${item?.created_by_company_id}/${item._id}`}>
                                                                                        <CButton id="allocate_driver"
                                                                                            className="allocate_accept_driver"

                                                                                        >
                                                                                            <img src={accepticonimg} alt="images" />
                                                                                        </CButton>
                                                                                    </Link>
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => { setSelectedId(item._id); setDelvisible(true); setCompanyId(item.created_by_company_id) }}
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginBottom: '6px'
                                                                                    }}
                                                                                    className="reject_icon"
                                                                                >
                                                                                    <img src={deleteiconimg} alt="images" />
                                                                                </div>
                                                                                <div>
                                                                                    <Link
                                                                                        to={`/access-edit-trip/${item?.created_by_company_id}/${item._id}`}
                                                                                    >
                                                                                        <CButton className="allocate_accept_driver">
                                                                                            <img src={editicon} alt="img" />
                                                                                        </CButton>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </CTableDataCell> : ''
                                                                }

                                                                {
                                                                    item.trip_status == "Booked" || item.trip_status == "Approved" ? <>

                                                                        <div className="">
                                                                            <button className="btn btn-warning" onClick={() => { retrieveTrip(item._id, 'Pending') }}>Retrieve Trip  </button>
                                                                        </div>
                                                                    </> : ''
                                                                }

                                                                <CTableDataCell>
                                                                    <div className="view_details_btn">
                                                                        <Link to={`/trips/view-trip-details/${item._id}`}>
                                                                            View Details
                                                                        </Link>
                                                                    </div>
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        ))
                                                        : ""}
                                                </CTableBody>
                                            </CTable> : <EmptyData />}

                                        {
                                            data?.length > 0 ?
                                                <div
                                                    className="pagination-outer"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <div
                                                        className="prev_btn"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <button onClick={() => handlePrePage()}>
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div className="previous-page">
                                                        {/* <ul>
                                                            {pageNumber}
                                                            <button className="dots_btn">
                                                                {pageIncreament}
                                                            </button>
                                                        </ul> */}

                                                        <ul>
                                                            {pageNumber.map((item, index) => (
                                                                <li key={item.id || index}>{item}</li> // Use a unique property like `item.id`
                                                            ))}
                                                            <button className="dots_btn">
                                                                {pageIncreament} {/* Ensure this also has a `key` if it's iterating */}
                                                            </button>
                                                        </ul>


                                                    </div>
                                                    <div className="next_btn">
                                                        <button onClick={() => handleNextPage()}>Next</button>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                    }

                                    <CModal
                                        alignment="center"
                                        visible={delvisible}
                                        onClose={() => setDelvisible(false)}
                                    >
                                        <CModalBody>
                                            <CRow>
                                                <CCol xs={12}>
                                                    <CCard className="mb-4 delete_vehicle_popup">
                                                        <CCardBody>
                                                            <img src={deletepopup} alt="danger" />
                                                            <h2>Are you Sure</h2>
                                                            <p>You want to delete this trip ?</p>
                                                        </CCardBody>
                                                        <div className="delete_vehicle_popup_outer">
                                                            <CButton
                                                                className="delete_popup"
                                                                onClick={() => handleDeletItem()}
                                                            >
                                                                Delete
                                                            </CButton>
                                                            <CButton
                                                                className="cancel_popup"
                                                                onClick={() => setDelvisible(false)}
                                                            >
                                                                Cancel
                                                            </CButton>
                                                        </div>
                                                    </CCard>
                                                </CCol>
                                            </CRow>
                                        </CModalBody>
                                    </CModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}



export default AllAccountTrips