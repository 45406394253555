import React, { useEffect, useState, useRef } from "react";
import CustomerSidebar from './CustomerSidebar';
import AppHeader from '../TopBar/AppHeader';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CCardBody,
    CRow,
    CCard,
    CCol,
    CFormInput,
    CFormLabel,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";

const Customerhistory = () => {

    const [loader, setLoader] = useState(false);


    return (
        <>
            <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        <CustomerSidebar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">

                            <AppHeader />
                            <div
                                className="body flex-grow-1 px-3"
                                style={{ paddingBottom: "20px" }}
                            >
                                <h1 className="heading-for-every-page">History</h1>
                                <div className="active-trip-outer">

                                    <CTable
                                        align="middle"
                                        className="mb-0 mt-3"
                                        hover
                                        responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>
                                                    Trip ID
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Passenger
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Departure date
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Departure address
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Destination
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Service rating
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Trip status
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Action
                                                </CTableHeaderCell>


                                            </CTableRow>
                                        </CTableHead>

                                        <CTableBody>
                                            <CTableRow >
                                                <CTableDataCell>
                                                    1
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    New
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    12 may
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    panchkula
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    Rohtak
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    6
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    Completed
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    edit
                                                </CTableDataCell>


                                            </CTableRow>



                                        </CTableBody>
                                    </CTable>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customerhistory