import React from 'react';
import CustomerSidebar from './CustomerSidebar';
import AppHeader from '../TopBar/AppHeader';
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CFormLabel,
    CFormSelect,
    CRow,
    CFormInput,
  } from "@coreui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import { useNavigate } from "react-router-dom";


const Customeraccount = () => {

    const navigate = useNavigate();


    const validationSchema = Yup.object({
        firstname: Yup.string().required("First Name is required"),
        lastname: Yup.string().required("Last Name is required"),
        phone: Yup.string()
          .required("Phone number is required")
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
         email: Yup.string()
         .required("Email address is required"), 
        country: Yup.string().required("Please select the Country"),
        vehicle: Yup.string().required("Please select a vehicle"),
        paymentMethod: Yup.string().required("Please select a payment method"),
        pickupDateTime: Yup.date().required("Pickup date and time are required"),
      });


  return (
    <><div className="container-fluidd">
    <div className="col-md-12">
        <div>
            <CustomerSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div
                    className="body flex-grow-1 px-3"
                    style={{ paddingBottom: "20px" }}
                >
                    <h1 className="heading-for-every-page">Account</h1>
                    <div className="active-trip-outer">
                    <Formik
                initialValues={{
                  firstname: "",
                  lastname:"",
                  phone: "",
                  email: "",
                  country: "",
                  vehicle: "",
                  paymentMethod: "cash",
                  pickupDateTime: null,
                 
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {

                 
                 
                  console.log("Form Submitted:", values);
                  
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <CRow>
                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="firstname">First Name *</CFormLabel>
                        <Field name="firstname" as={CFormInput} />
                        <ErrorMessage name="firstname" component="div" className="text-danger" />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="lastname">Last Name *</CFormLabel>
                        <Field name="lastname" as={CFormInput} />
                        <ErrorMessage name="lastname" component="div" className="text-danger" />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="phone">Phone *</CFormLabel>
                        <Field name="phone" as={CFormInput} />
                        <ErrorMessage name="phone" component="div" className="text-danger" />
                      </CCol>


                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="email">Email *</CFormLabel>
                        <Field name="email" as={CFormInput} />
                        <ErrorMessage name="email" component="div" className="text-danger" />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="country">Country *</CFormLabel>
                        <Field name="country" as={CFormSelect}>
                          <option value="">Select Country</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Nepal">Nepal</option>
                          <option value="No">No</option>
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="vehicle">Select Vehicle *</CFormLabel>
                        <Field name="vehicle" as={CFormSelect}>
                          <option value="">Select Vehicle</option>
                          <option value="Car">Car</option>
                          <option value="Van">Van</option>
                        </Field>
                        <ErrorMessage
                          name="vehicle"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="paymentMethod">Payment Method *</CFormLabel>
                        <Field name="paymentMethod" as={CFormSelect}>
                          <option value="cash">Cash</option>
                          <option value="credit">Credit</option>
                        </Field>
                        <ErrorMessage
                          name="paymentMethod"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>


                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="pickupDateTime">Pickup Date and Time *</CFormLabel>
                        <DatePicker
                          className="form-control"
                          selected={values.pickupDateTime ? new Date(values.pickupDateTime) : null}
                          onChange={(date) => setFieldValue("pickupDateTime", date)}
                          showTimeSelect
                          timeIntervals={1}
                        />
                        <ErrorMessage
                          name="pickupDateTime"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                    </CRow>

                    <div className="d-flex justify-content-center mt-4">
                      <CButton type="submit" className="submit-btn">
                        Submit
                      </CButton>
                      <CButton
                        type="button"
                        onClick={() => navigate("/customer/dashboard")}
                        className="cancel-btn"
                      >
                        Cancel
                      </CButton>
                    </div>
                  </Form>
                )}
              </Formik>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div></>
  )
}

export default Customeraccount