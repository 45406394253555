import React, { useEffect, useState, useContext, useRef } from "react";
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CCardBody,
    CRow,
    CCard,
    CCol,
    CFormInput,
    CFormLabel,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import { getCompanyDetails, getHotelDetails, getFareDetails, getCompanyById } from "../../../utils/api";
import EmptyData from "../../EmptyData";
import ReactPaginate from 'react-paginate';
import SuperAdminSideBar from '../Sidebar/SideBar'
import AppHeader from '../../TopBar/AppHeader'
import { Link, useParams } from 'react-router-dom'
import backtovehicle from '../../../assets/images/left-arrow.png';



const CompFareDetails = () => {

    const CompID = useParams().id;
    const [Fare, setFare] = useState([]);
    const [CompanyData, setCompanyData] = useState([]);
    const [currentPage3, setCurrentPage3] = React.useState(1);
    const [pageLimit3, setPageLimit3] = React.useState(3);
    const [maxPage3, setMaxPage3] = React.useState(3);
    const [minPage3, setMinPage3] = React.useState(0);
    const recordPage3 = 10;
    const lastIndex3 = currentPage3 * recordPage3;
    const firstIndex3 = lastIndex3 - recordPage3;
    const data3 = Fare?.slice(firstIndex3, lastIndex3);
    const nPage3 = Math.ceil(Fare?.length / recordPage3);
    const number3 = [...Array(nPage3 + 1).keys()]?.slice(1);

    const pageNumber3 = number3.map((num, i) => {
        if (num < maxPage3 + 1 && num > minPage3) {
            return (
                <>
                    <li
                        key={i}
                        className={currentPage3 == num ? `active_btn ` : `unactive_btn`}
                    >
                        <button onClick={() => changePage3(num)}>{num}</button>
                    </li>
                </>
            );
        } else {
            return null;
        }
    });

    const handlePrePage3 = () => {
        if (currentPage3 !== 1) {
            setCurrentPage3(currentPage3 - 1);
            if ((currentPage3 - 1) % pageLimit3 == 0) {
                setMaxPage3(maxPage3 - pageLimit3);
                setMinPage3(minPage3 - pageLimit3);
            }
        }
    };

    const handleNextPage3 = () => {
        if (currentPage3 !== nPage3) {
            setCurrentPage3(currentPage3 + 1);
            if (currentPage3 + 1 > maxPage3) {
                setMaxPage3(maxPage3 + pageLimit3);
                setMinPage3(minPage3 + pageLimit3);
            }
        }
    };

    const changePage3 = (id) => {
        setCurrentPage3(id);
    };
    let pageIncreament3 = null;
    if (data3?.length > maxPage3) {
        pageIncreament3 = <li onClick={handleNextPage3}>&hellip;</li>;
    }


    useEffect(() => {
        fetchCompanyById();
        fetchFareDetails();
    }, [])

    const fetchFareDetails = () => {
        getFareDetails(CompID).then((response) => {
            console.log("Fare Details:", response);
            setFare(response.result);
        }).catch((error) => {
            console.error("Error fetching Company Details:", error);
        });
    }

   

    const fetchCompanyById = () => {
        getCompanyById(CompID).then((response) => {
            console.log("Company Details ID________:", response);
            setCompanyData(response?.result)

        }).catch((error) => {
            console.error("Error fetching Company Details:", error);
        });
    }

    return (

        <>
            <div className='container-fluidd'>
                <div className="col-md-12">
                    <div>
                        <SuperAdminSideBar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div className="body flex-grow-1 px-3">
                                <h1 className="heading-for-every-page">
                                    <Link to={'/super-admin/all-companies'}>
                                        <img src={backtovehicle} alt="edit" /> Back
                                    </Link>
                                </h1>
                                <div className='FareDetails'>
                                    <h5 className='ps-4'>Fare Details of {CompanyData?.company_name}</h5>
                                    <div className='active-trip-outer mx-4'>
                                        {
                                            data3.length ? <CTable
                                                align="middle"
                                                className="mb-0 text-center mt-3"
                                                hover
                                                responsive>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell>
                                                            Sr. No.
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Vehicle Type
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Fare per km
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Minimum Fare
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Fare Per Minute
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell>
                                                            Waiting Fare
                                                        </CTableHeaderCell>

                                                    </CTableRow>
                                                </CTableHead>
                                                {
                                                    data3?.length > 0 ? data3.map((item, index) => (
                                                        <CTableBody>
                                                            <CTableRow>
                                                                <CTableDataCell>
                                                                    <div>{firstIndex3 + index + 1}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.vehicle_type}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.vehicle_fare_per_km}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.minimum_fare}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.price_per_min}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item.waiting_fare}</div>
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        </CTableBody>
                                                    )) :
                                                        ""}
                                            </CTable> : <EmptyData />
                                        }

                                        {
                                            data3?.length > 0 ?
                                                <div
                                                    className="pagination-outer"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <div
                                                        className="prev_btn"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <button onClick={() => handlePrePage3()}>
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div className="previous-page">
                                                        <ul>
                                                            {pageNumber3.map((item, index) => (
                                                                <li key={item.id || index}>{item}</li>
                                                            ))}
                                                            <button className="dots_btn">
                                                                {pageIncreament3}
                                                            </button>
                                                        </ul>
                                                    </div>
                                                    <div className="next_btn">
                                                        <button onClick={() => handleNextPage3()}>Next</button>
                                                    </div>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompFareDetails