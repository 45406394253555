import React, { useState } from "react";
import CustomerSidebar from "./CustomerSidebar";
import AppHeader from "../TopBar/AppHeader";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormLabel,
  CFormSelect,
  CRow,
  CFormInput,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";

const CreateCustomerTrip = () => {
  const navigate = useNavigate();
  const [coordinatesFrom, setCoordinatesFrom] = useState({ lat: null, lng: null });
  const [coordinatesTo, setCoordinatesTo] = useState({ lat: null, lng: null });
  const [tripFrom, setTripFrom] = useState("");
  const [tripTo, setTripTo] = useState("");

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    passengers: Yup.string().required("Please select the number of passengers"),
    vehicle: Yup.string().required("Please select a vehicle"),
    paymentMethod: Yup.string().required("Please select a payment method"),
    pickupDateTime: Yup.date().required("Pickup date and time are required"),
    tripFrom: Yup.string().required("Trip From is required"),
    tripTo: Yup.string().required("Trip To is required"),
  });

  return (
    <div className="container-fluid px-0">
      <div className="col-md-12">
        <div>
          <CustomerSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
            <div className="body flex-grow-1 px-3" style={{ paddingBottom: "20px" }}>
              <h1 className="heading-for-every-page">Create Trip</h1>
              <Formik
                initialValues={{
                  name: "",
                  phone: "",
                  passengers: "",
                  vehicle: "",
                  paymentMethod: "cash",
                  pickupDateTime: null,
                  tripFrom: "",
                  tripTo: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {

                  const existingTrips = JSON.parse(localStorage.getItem("customerTrips")) || []; // Retrieve existing trips or initialize an empty array
                  const newTrip = {
                    ...values,
                    coordinatesFrom,
                    coordinatesTo,
                  };
                  existingTrips.push(newTrip); // Add new trip data to the array
                  localStorage.setItem("customerTrips", JSON.stringify(existingTrips)); // Save back to localStorage
                  navigate("/customer/dashboard")
                  console.log("Trip Saved:", newTrip);
                  console.log("All Trips:", existingTrips);
                 
                  // console.log("Form Submitted:", values);
                  // console.log("Trip From Coordinates:", coordinatesFrom);
                  // console.log("Trip To Coordinates:", coordinatesTo);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <CRow>
                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="name">Name *</CFormLabel>
                        <Field name="name" as={CFormInput} />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="phone">Phone *</CFormLabel>
                        <Field name="phone" as={CFormInput} />
                        <ErrorMessage name="phone" component="div" className="text-danger" />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="passengers">Passengers *</CFormLabel>
                        <Field name="passengers" as={CFormSelect}>
                          <option value="">Select Passengers</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Field>
                        <ErrorMessage
                          name="passengers"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="vehicle">Select Vehicle *</CFormLabel>
                        <Field name="vehicle" as={CFormSelect}>
                          <option value="">Select Vehicle</option>
                          <option value="Car">Car</option>
                          <option value="Van">Van</option>
                        </Field>
                        <ErrorMessage
                          name="vehicle"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="paymentMethod">Payment Method *</CFormLabel>
                        <Field name="paymentMethod" as={CFormSelect}>
                          <option value="cash">Cash</option>
                          <option value="credit">Credit</option>
                        </Field>
                        <ErrorMessage
                          name="paymentMethod"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="pickupDateTime">Pickup Date and Time *</CFormLabel>
                        <DatePicker
                          className="form-control"
                          selected={values.pickupDateTime ? new Date(values.pickupDateTime) : null}
                          onChange={(date) => setFieldValue("pickupDateTime", date)}
                          showTimeSelect
                          timeIntervals={1}
                        />
                        <ErrorMessage
                          name="pickupDateTime"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="tripFrom">Trip From *</CFormLabel>
                        <PlacesAutocomplete
                          value={tripFrom}
                          onChange={(value) => {
                            setFieldValue("tripFrom", value);
                            setTripFrom(value);
                          }}
                          onSelect={async (value) => {
                            setFieldValue("tripFrom", value);
                            setTripFrom(value);
                            const results = await geocodeByAddress(value);
                            const latLng = await getLatLng(results[0]);
                            setCoordinatesFrom(latLng);
                          }}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <Field
                                {...getInputProps({
                                  placeholder: "Search Places...",
                                  className: "form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#f0f0f0"
                                      : "#ffffff",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, { style })}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <ErrorMessage
                          name="tripFrom"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>

                      <CCol md={6} className="mb-4">
                        <CFormLabel htmlFor="tripTo">Trip To *</CFormLabel>
                        <PlacesAutocomplete
                          value={tripTo}
                          onChange={(value) => {
                            setFieldValue("tripTo", value);
                            setTripTo(value);
                          }}
                          onSelect={async (value) => {
                            setFieldValue("tripTo", value);
                            setTripTo(value);
                            const results = await geocodeByAddress(value);
                            const latLng = await getLatLng(results[0]);
                            setCoordinatesTo(latLng);
                          }}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <Field
                                {...getInputProps({
                                  placeholder: "Search Places...",
                                  className: "form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#f0f0f0"
                                      : "#ffffff",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, { style })}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <ErrorMessage
                          name="tripTo"
                          component="div"
                          className="text-danger"
                        />
                      </CCol>
                    </CRow>

                    <div className="d-flex justify-content-center mt-4">
                      <CButton type="submit" className="submit-btn">
                        Submit
                      </CButton>
                      <CButton
                        type="button"
                        onClick={() => navigate("/customer/dashboard")}
                        className="cancel-btn"
                      >
                        Cancel
                      </CButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomerTrip;
