import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import loginImg from "../../assets/images/login-img.png";
import loginLogo from "../../assets/images/login-logo.png";
import loginbg from "../../assets/images/login-bg.png";
import { Link, useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { resendLoginOtp, userLogin, verifyLoginOtp } from "../../utils/api";
import { toast } from 'react-toastify';
import userContext from "../../utils/context";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { ClipLoader } from "react-spinners";
import { socketContext } from "../../App";
import { CModal, CModalBody } from "@coreui/react";
import OTPInput from "react-otp-input";

function Login() {
  const { socket } = useContext(socketContext);
  const { user, setUser } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    phoneNo: Yup.string().required("Email Address or Phone Number is required"),
    password: Yup.string().min(6, "Password must be 6 characters long").required("Password is required"),
  });

  const initialValues = {
    phoneNo: "",
    password: "",
  };
  const [passVissible, setPassVissible] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [otp, setOtp] = useState('');
  const [uniqueId, setUniqueId] = useState(null);
  const [resendTimer, setResendTimer] = useState(120); // 2 min timer

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      userLogin({
        email: values.phoneNo,
        password: values.password
      }).then((response) => {
        if (response.data.code === 200) {
          setUser(response.data.result);
          toast.success(`${response.data.message}`, {
            position: 'top-right',
            autoClose: 1000,
          });
          localStorage.setItem("token", response.data.jwtToken);
          const token = response.data.result.token;
          if (response.data.result.role === "COMPANY") {
            socket.emit("addWebUser", { token });
            navigate("/taxi/dashboard");
          } else if (response.data.result.role === "DRIVER") {
            socket.emit("addWebNewDriver" , { token });
            return navigate("/past-trips");
          } else if (user?.role === "SUPER_ADMIN" || user?.role === "HOTEL" || user?.role === "ADMIN") {
            socket.emit("addUser", { token });
            navigate("/dashboard");

          }
        } else if (response.data.code === 205) {
          setShowVerification(true);
          setOtp(response.data.OTP)
          toast.success(`${response.data.message}`, {
            position: 'top-right',
            autoClose: 1500,
          });
          setUniqueId(response.data.uniqueId);
          setResendTimer(120); // reset timer
        } else {
          toast.warning(response?.data?.message, {
            position: 'top-right',
            autoClose: 1000,
          });
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => { setLoading(false) });
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    let response = await verifyLoginOtp({ otp: otp, login_sms_otp_uid: uniqueId });
    if (response?.data.code === 200) {
      setUser(response.data.result);
      toast.success(`${response.data.message}`, {
        position: 'top-right',
        autoClose: 1000,
      });
      localStorage.setItem("token", response.data.jwtToken);
      const token = response.data.result.token;
      setLoading(false);
      if (response.data.result.role === "SUPER_ADMIN" || response.data.result.role === "ADMIN") {
        socket.emit("addUser", { token });
        navigate("/super-admin/dashboard");

      }
    } else {
      toast.warning(response?.data?.message, {
        position: 'top-right',
        autoClose: 1000,
      });
      setLoading(false);
    }
  };

  const handleResend = async () => {
    let response = await resendLoginOtp({ login_sms_otp_uid: uniqueId });
    if(response.data.code === 205){
      setUniqueId(response.data.uniqueId)
      toast.success(`${response.data.message}`, {
        position: 'top-right',
        autoClose: 1000,
      });
      setResendTimer(120);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    let interval;
    if (showVerification && resendTimer > 0) {
      interval = setInterval(() => setResendTimer((prev) => prev - 1), 1000);
    } else if (resendTimer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showVerification, resendTimer]);

  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    setIcon(!passVissible ? eye : eyeOff);
  };

  return (
    <div className="container-login"
      style={{
        backgroundImage: `url(${loginbg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100%",
      }}>
      <MDBContainer fluid className="p-0 ps-0 pe-0 my-0 h-custom custom-login-form">
        <MDBRow>
          <MDBCol col="4" md="8">
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="login-left-content">
                <img src={loginLogo} className="login-" loading="lazy" alt="" width="100%" />
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="form-label">Email Address or Phone Number</label>
                  <MDBInput
                    id="phoneNumber"
                    type="text"
                    size="lg"
                    value={formik.phoneNo}
                    {...formik.getFieldProps("phoneNo")}
                    maxLength="50"
                    className={clsx("form-control bg-transparent", {
                      "is-invalid": formik.touched.phoneNo && formik.errors.phoneNo,
                      "is-valid": formik.touched.phoneNo && !formik.errors.phoneNo,
                    })}
                    name="phoneNo"
                    autoComplete="off"
                  />
                  {formik.errors.phoneNo && formik.touched.phoneNo && <div className="text-danger text-start">{formik.errors.phoneNo}</div>}
                </div>
                <div className="mb-4" id="pwd_field">
                  <label htmlFor="password" className="form-label">Password</label>
                  <MDBInput
                    id="password"
                    type={passVissible ? "text" : "password"}
                    size="lg"
                    {...formik.getFieldProps("password")}
                    maxLength="50"
                    className={clsx("form-control bg-transparent input_pwd", {
                      "is-invalid": formik.touched.password && formik.errors.password,
                      "is-valid": formik.touched.password && !formik.errors.password,
                    })}
                    name="password"
                    autoComplete="off"
                  />
                  {formik.errors.password && formik.touched.password && <div className="text-danger text-start">{formik.errors.password}</div>}
                  <span className="flex justify-around items-center eye_pwd_icon">
                    <Icon onClick={() => {
                      setPassVissible(!passVissible);
                      handleToggle();
                    }} className="absolute mr-10" icon={icon} size={25} />
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-4 login-remember-forgot">
                  <Link to={"/forgot-password"} className="forgot-pwd" href="!#">
                    Forgot password?
                  </Link>
                </div>
                <div className="text-center text-md-start mt-4 pt-2">
                  <button className="custom-login btn btn-primary" type="submit">
                    {loading ? <ClipLoader color="#000000" /> : "Login"}
                  </button>
                </div>
                
              </div>
            </form>
          </MDBCol>
          <MDBCol col="10" md="4">
            <img src={loginImg} className="img-fluid-login" loading="lazy" alt="" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <CModal alignment="center" visible={showVerification} onClose={() => setShowVerification(false)} backdrop="static">
        <CModalBody>
          <div className="container-login" style={{
            backgroundImage: `url(${loginbg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100%",
          }}>
            <div className="login-left-content">
              <img src={loginLogo} height={100} width={200} alt="Logo" /><br /><br />
              <p>Please Enter your OTP</p>
              <div className="opt-outer-login">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  inputType="number"
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus
                  inputStyle={{ backgroundColor: "#F5F6F8", height: "40px", border: "1px solid lightgrey", borderRadius: "10px" }}
                />
              </div><br />
              <button className="custom-login btn btn-primary py-2" onClick={handleSubmit}>
                {loading ? <ClipLoader color="#000000" /> : "Verify"}
              </button>
              <div className="mt-3">
                {resendTimer > 0 ? (
                  <p>Resend OTP in {Math.floor(resendTimer / 60)}:{String(resendTimer % 60).padStart(2, '0')}</p>
                ) : (
                  <button onClick={handleResend} className="btn" style={{
                    backgroundColor:"#0083c9",
                    color:"#fff"
                  }}>Resend OTP</button>
                )}
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default Login;