import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import applogo from '../../assets/images/taxi-logo.png'
// import sideNavBar from './Siderbardata';
import CustomerSidebarData from './CustomerSidebarData';
import { SuperBar } from '../SuperAdmin/Sidebar/AppSideNavBar';
import { Link } from "react-router-dom";

const CustomerSidebar = () => {

    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <>
     <CSidebar
        position="fixed"
        unfoldable={unfoldable}
      visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: 'set', sidebarShow: visible })
        }}
      >
        <CSidebarBrand className="d-none d-md-flex" to="/">
          {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
           <Link to={`/dashboard`} >
          <img src={applogo} height={50} width={100}/>
          </Link>
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <SuperBar className="sidebar_outer" items={CustomerSidebarData}/>
          </SimpleBar>
        </CSidebarNav>
      </CSidebar>
    </>
  )
}

export default CustomerSidebar