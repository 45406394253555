import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const LineCharts = ({width , height, data}) => {
  const [xAxisData, setXAxisData] = useState([]); // X-axis data points
  const [chartData, setChartData] = useState([]); // Line series data points

  useEffect(() => {
    // Simulated API call to fetch data
    const fetchData = async () => {
      try {
        // Example response (replace with real API call if needed)
        const response = {
          xAxis: [1, 2, 3, 5, 8, 10], // X-axis points
          data: [2, 5.5, 2, 8.5, 1.5, 5], // Y-axis values for the line
        };

        // Set state with fetched data
        setXAxisData(response.xAxis);
        setChartData(response.data);
      } catch (error) {
        console.error('Error fetching line chart data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <LineChart
        xAxis={[{ data: xAxisData }]} // Dynamic x-axis data
        series={[
          {
            data: chartData, // Dynamic series data
          },
        ]}
        style= {{
          width: `${width}%`,
          height: height,
        }}
        // width={width?.width}
        // height={width?.height}
      />
    </>
  );
};

export default LineCharts;
