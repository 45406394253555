import React, { useState, useEffect, useMemo } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { green } from '@mui/material/colors';

const PieCharts = ({ width, height, data , name }) => {
  const [chartData, setChartData] = useState([]);

  const data_list = useMemo(() => data, [data])

  useEffect(() => {

    if (data_list) {
      fetchData();
    }

  }, [data_list]);


  const fetchData = async () => {
    try {
      const formattedData = data_list.map((item, index) => ({
        id: index,
        value: item.value,
        label: item.label+`(${item.value})`,
      }));

      setChartData(formattedData);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };


  return (
    <>
      <div>
        <h4 className='text-center'>{name}</h4>
        <PieChart
          colors={['red', '#02b2af', '#ffd04e', 'green', 'blue']}
          series={[
            {
              data: chartData,
            },
          ]}
          style={{
            width: `${width}%`,
            height: height,
          }}
        />
      </div>
    </>
  );
};

export default PieCharts;
