import React, { useState, useEffect, useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const BarCharts = ({ width, height, data, typex , typey }) => {

  const data_list = useMemo(() => data, [data])

  const [categories, setCategories] = useState([]); // X-axis categories
  const [chartData, setChartData] = useState([]); // Series data



  useEffect(() => {

    if (data_list) {
      fetchData();
    }

  }, [data_list]);



  const fetchData = async () => {
    try {

      const categories = [];
      const chartData = [];

      data_list.forEach((item) => {
        categories.push(item.label);
        chartData.push(item.tripCount ? item.tripCount : item.totalRevenue);
      })

      console.log(data_list, " categories ______________" , chartData );

      setCategories(categories);
      setChartData(chartData);
    } catch (error) {
      console.error('Error fetching bar chart data:', error);
    }
  };

  return (
    <>
      <div>
     
        <BarChart
          xAxis={[
            {
              id: 'barCategories',
              data: categories, // Dynamic categories
              scaleType: 'band',
              tickPlacement: 'middle',
              label: typex,
            },
          ]}
          yAxis={[
            {
              label: typey,
            },
          ]}
          series={[
            {
              data: chartData,
            },
          ]}
          style={{
            width: `${width}%`,
            height: height,
          }}
        // width={width?.width}
        // height={width?.height}
        />
      </div>
    </>

  );
};

export default BarCharts;
