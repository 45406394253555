import React, { useEffect, useState, useContext, useRef } from "react";
import CustomerSidebar from './CustomerSidebar';
import AppHeader from '../TopBar/AppHeader';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CCardBody,
    CRow,
    CCard,
    CCol,
    CFormInput,
    CFormLabel,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../AppLoader";
import EmptyData from "../EmptyData";
import editiconimg from '../../assets/images/editicon.png';
import moment from "moment";



const Customerdashboard = () => {

    const [tripDetail, setTripDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = tripDetail?.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(tripDetail?.length / recordPage);


    useEffect(() => {
        getCustomerTrip();
    }, []);

    const getCustomerTrip = () => {

        let date = JSON.parse(localStorage.getItem('customerTrips'));
        setTripDetail(date);
        console.log(date, "wiptor");
    }

    return (
        <>
            <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        <CustomerSidebar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div
                                className="body flex-grow-1 px-3"
                                style={{ paddingBottom: "20px" }}
                            >
                                <h1 className="heading-for-every-page">Dashboard</h1>
                                <div className="active-trip-outer">
                                    <Link to={`/customer/createTrip`}>
                                        <CButton className='view_details_btn'>CREATE TRIP</CButton>
                                    </Link>

                                    {
                                        loading ? <AppLoader /> : (<>

                                            {
                                                data?.length > 0 ?
                                                    <CTable
                                                        align="middle"
                                                        className="mb-0 mt-3"
                                                        hover
                                                        responsive>
                                                        <CTableHead>
                                                            <CTableRow>
                                                                <CTableHeaderCell>
                                                                    Sr. No.
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Name
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Phone
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Trip From
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Trip To
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Passengers
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Vehicle
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Payment Method
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Date and Time
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    Action
                                                                </CTableHeaderCell>

                                                            </CTableRow>
                                                        </CTableHead>

                                                        <CTableBody>
                                                            {data?.map((item, index) => (
                                                                <CTableRow >
                                                                    <CTableDataCell>
                                                                        <div>{firstIndex + index + 1}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.name}</div>
                                                                    </CTableDataCell>

                                                                    <CTableDataCell>
                                                                        <div>{item?.phone}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.tripFrom}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.tripTo}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.passengers}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.vehicle}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>{item?.paymentMethod}</div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <div>
                                                                            {moment(item.pickupDateTime).format(
                                                                                "MMMM Do YYYY, h:mm a"
                                                                            )}
                                                                        </div>
                                                                    </CTableDataCell>

                                                                    <CTableDataCell>
                                                                        <Link to={`/customer/editTrip/${firstIndex + index + 1}`}>
                                                                        <CButton
                                                                            id="edit_company_btn"
                                                                            className="pt-1"
                                                                        >
                                                                            <img
                                                                                src={editiconimg}
                                                                                alt="edit"
                                                                                style={{ height: '33px', width: '34px' }}
                                                                            />{" "}
                                                                        </CButton>
                                                                        </Link>

                                                                    </CTableDataCell>

                                                                </CTableRow>
                                                            ))}


                                                        </CTableBody>
                                                    </CTable> : <EmptyData />
                                            }

                                        </>)
                                    }


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customerdashboard